// Entry point for the build script in your package.json

// js libraries
import "./vendor/turbo";
import "./vendor/jquery";
import "./vendor/rails";
import './vendor/turbolinks';
import 'bootstrap';
import 'popper.js';
import './vendor/select2';
import './vendor/flatpickr';

// custom js
import './src/select2';
import './src/flatpickr';
import './src/ajax_select2/devices';
import './src/registrations';
import './src/ticket_search';
import './src/flash';
import './src/trade_in_cases';
import "./controllers";
