$(document).on('turbolinks:load turbo:frame-load', function(){
  const $deviceSelect = $('.js-device-select');
  const $replacementTypeField = $('.js-replacement-type-select');

  setupReplacementReasonsSelect();
  setupDeviceSelect($deviceSelect);
  updateProposedDeviceType(); // so it populates values on the edit
  toggleOriginalDevice2();
  registerOnChangeEventHandlers($deviceSelect, $replacementTypeField);
});

function setupReplacementReasonsSelect() {
  const $replacementReasonsSelect = $('.js-replacement-reasons-select');

  const ajaxParams = {
    url: '/search/replacement_reasons',
    dataType: 'json',
    type: 'GET',
    delay: 300
  };

  $replacementReasonsSelect.select2({
    width: '100%',
    allowClear: true,
    placeholder: 'Select',
    minimumInputLength: 0,
    ajax: ajaxParams,
  });
}

function setupDeviceSelect($deviceSelect) {
  const ajaxParams = {
    url: '/search/devices',
    dataType: 'json',
    type: 'GET',
    delay: 300,
    data: function(params) {
      const replacementTypeId = $('.js-replacement-type-select').val();
      const consumerId = $('.js-consumer-id').val();
      return {
        query: params.term,
        // eslint-disable-next-line camelcase
        replacement_type_id: replacementTypeId,
        // eslint-disable-next-line camelcase
        consumer_id: consumerId
      };
    },
    processResults: function(data) {
      return {
        results: data.map((device) => {
          return {
            id: device.id,
            text: device.device_details,
            deviceCode: device.code,
            deviceSerialNumber: device.serial_number,
            deviceTypeName: device.device_type_name,
            deviceTypeId: device.device_type_id,
            bundle: device.bundle,
          };
        })
      };
    },
  };

  $deviceSelect.select2({
    width: '100%',
    allowClear: true,
    placeholder: 'Select',
    minimumInputLength: 12,
    maximumInputLength: 14,
    ajax: ajaxParams,
  }).on('select2:select', function(e) {
    populateDeviceDetails($(this), e.params.data);
  }).on('select2:clear', function() {
    clearDeviceDetails($(this));
  });
}

function registerOnChangeEventHandlers($deviceSelect, $replacementTypeField) {
  $replacementTypeField.on('change', function() {
    $deviceSelect.each(function() {
      $(this).val(null).trigger('change').trigger('select2:clear');
    });
  });

  $deviceSelect.on('change', updateProposedDeviceType);

  $('.js-original-device-select')
    .on('select2:select', function(e) {
      toggleOriginalDevice2();
    }).on('select2:clear', function() {
      toggleOriginalDevice2();
      clearDeviceDetails($(this));
    });
}

function populateDeviceDetails(element, data) {
  if ($.isEmptyObject(data)) {
    return;
  }

  const $formObject = element.closest('.js-device-inputs');

  const $deviceTypeField = $formObject.find('.js-device-type-input');
  $deviceTypeField.val(data["deviceTypeName"]);

  const $deviceTypeIdField = $formObject.find('.js-original-device-type-id');
  $deviceTypeIdField.val(data["deviceTypeId"]);

  const $deviceCodeField = $formObject.find('.js-original-device-serial-number');
  $deviceCodeField.val(data["deviceSerialNumber"]);
}

function clearDeviceDetails(element) {
  const $formObject = element.closest('.js-device-inputs');

  const $deviceTypeField = $formObject.find('.js-device-type-input');
  $deviceTypeField.val("");
  const $deviceTypeIdField = $formObject.find('.js-original-device-type-id');
  $deviceTypeIdField.val("");
  const $deviceCodeField = $formObject.find('.js-original-device-serial-number');
  $deviceCodeField.val("");
}

function updateProposedDeviceType() {
  const $proposedDeviceTypeGroupField = $('.js-proposed-device-type-group-select');
  if ($proposedDeviceTypeGroupField.length === 0) {
    return;
  }

  const deviceTypeIds = $('.js-device-select').toArray().map(deviceSelect => {
    const selection = $(deviceSelect).select2('data')[0];
    const deviceTypeId = selection.deviceTypeId || $(selection.element).data('deviceTypeId');

    return deviceTypeId;
  }).filter(id => id);

  const replacementTypeId = $('.js-replacement-type-select').val();

  // eslint-disable-next-line camelcase
  const data = { device_type_ids: deviceTypeIds, replacement_type_id: replacementTypeId };
  const oldProposedDeviceTypeValue = $proposedDeviceTypeGroupField.val();
  fetchDeviceTypeGroups(data, $proposedDeviceTypeGroupField, () => {
    $proposedDeviceTypeGroupField.val(oldProposedDeviceTypeValue).trigger('change');
  });
}

function fetchDeviceTypeGroups(data = {}, $targetDeviceTypeField, onSuccess = () => {}) {
  $.ajax({
    url: '/search/device_type_groups',
    data: data,
    type: 'GET',
    dataType: 'json',
    success: function(data) {
      updateSelectOptions($targetDeviceTypeField, data);
      onSuccess();
    },
    error: function(xhr, status, error) {
      console.error('AJAX request failed');
      console.error(status, error);
    }
  });
}

function updateSelectOptions($select, data) {
  $select.empty();
  data.forEach(deviceType => {
    const option = new Option(deviceType.text, deviceType.id, true, true);
    $select.append(option);
  });
}

function toggleOriginalDevice2() {
  // early return if not on tickets form page
  if ($('.js-original-device-select').length === 0) {
    return;
  }

  const originalDeviceSelection = $('.js-original-device-select').select2('data')[0];

  // empty selection still returns result with id being empty string
  if (!originalDeviceSelection.id) {
    hideOriginalDevice2();
    return;
  }

  // on edit or rerender originalDeviceSelection.bundle is undefined, so data-bundle on original-device-select is used
  const isBundle = originalDeviceSelection.bundle || $('.js-original-device-select').data('bundle');
  if (isKitForKit() && isBundle === true) {
    showOriginalDevice2();
  } else {
    hideOriginalDevice2();
  }
}

function isKitForKit() {
  const selectedReplacementType = $('.js-replacement-type-select').select2('data')[0].element;
  return $(selectedReplacementType).data('kitForKit') === true;
}

function showOriginalDevice2() {
  const $originalDevice2Details = $('#original-device-2-details');
  toggleDisabledOnDeviceDetails($originalDevice2Details, false);

  $originalDevice2Details.removeClass('d-none');
}

function hideOriginalDevice2() {
  const $originalDevice2Details = $('#original-device-2-details');
  const originalDevice2Select = $originalDevice2Details.find('.js-device-select');
  originalDevice2Select.val("");

  clearDeviceDetails(originalDevice2Select);
  toggleDisabledOnDeviceDetails($originalDevice2Details, true);

  $originalDevice2Details.addClass('d-none');
}

function toggleDisabledOnDeviceDetails($container, isDisabled) {
  $container.find('select').prop('disabled', isDisabled);
}
