$(document).on('turbolinks:load', () => {
  $('.js-datepicker').flatpickr({
    enableTime: true,
    altInput: true,
    altFormat: "d.m.Y H:i",
    locale: {
      rangeSeparator: ' - '
    }
  });
});
